
export default {
    name: 'WatAcademyMedal',
    props:{
        medal:{
            type: Object,
            default: () => {}
        },
        index:{
            type: Number,
            default: 0
        }
    }
}
